import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';


const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${(props) => props.theme.body};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {
  
  let element = ref.current;

  ScrollTrigger.create({
    trigger: element,
    start:'bottom bottom',
    end:'bottom top',
    pin:true,   
    pinSpacing:false, 
    scrub:1,
    // markers:true,
  })

  return () => {
    ScrollTrigger.kill();
  };
}, [])

  return (
    <Section ref={ref} id="faq">
    <Title>Faq</Title>

    <Container>

<Box>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHERE CAN I VIEW MY NFTS?" >
  Once minted or bought simply connect to your OpenSea account to view your NFTs.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHAT IS THE METAVERSE?" >
  A metaverse is a network of 3D virtual worlds focused on social connection. In futurism and science fiction, it is often described as a hypothetical iteration of the Internet as a single, universal virtual world that is facilitated by the use of virtual and augmented reality headsets.

  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHY DO WE NEED ROYALTIES?" >
  The amount of royalties was fixed at 10% to finance the EHBCNFT Club projects. We have the ambition to organize multiple events around the world in order to strengthen the community and build a network of entrepreneurs and investors with the same mindset and common interests.
  </Accordion>
      <Accordion ScrollTrigger={ScrollTrigger} title="HOW DO I MINT NFT?" >
  Go to our Mint Now page from the main website navigation menu. Click the BUY button. Follow the prompts to connect your selected crypto wallet.<br/><br/>
  
*Ensure you have the correct cryptocurrency in your wallet and your wallet correctly set up to the Polygon Mainnet network. Expressive Human Board Club NFT is minted onto the Polygon blockchain so you will need MATIC currency to mint your Expressive Humans NFT.<br/><br/>

Once the wallet is connected, follow the prompts to mint your Expressive Human Board Club NFT.
  </Accordion>
</Box>
<Box>
<Accordion ScrollTrigger={ScrollTrigger} title="HOW CAN I USE MY NFT?" >
You will be able to use your NFT as an avatar in the Metaverse and our future video game. Holding also means that you are part of an exclusive network of investors and entrepreneurs.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHAT ARE THE EHBCNFT?
" >
The Expressive Human Board Club is a generative NFT collection of 10,000 hand-drawn expressive human beings that are ready to be dropped into the metaverse.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHAT IS THE FUSION PROCESS?
" >
NFt are the new trends in crypto market, Holding NFT can offer much more advantages and benefits to every holder.
  </Accordion>
  
  <Accordion ScrollTrigger={ScrollTrigger} title="WHY DID WE LAUNCH ON POLYGON?" >
  High-Speed Transaction: One of the major advantages is that it helps its users to experience a smooth and instant payment process.<br/><br/>
Cost-Efficient : Another major advantage is that the transaction fee here is very low when compared to several other blockchain networks.<br/><br/>
Advanced Security : It functions on its own algorithm to make the platform more secure.<br/><br/>
Interoperability : This platform allows its users to trade with NFTs from various side-chains.<br/><br/>
  </Accordion>
  
  
</Box>
    </Container>
    </Section>
  )
}

export default Faq