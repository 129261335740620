import { createGlobalStyle } from "styled-components";
import "@fontsource/akaya-telivigala"
import "@fontsource/sora"




const GlobalStyles = createGlobalStyle`

${'' /* 
*{
    outline: 1px solid red !important;
} */}

*,*::before,*::after{
    margin: 0;
    padding: 0;
}

html {
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
}

body{
    font-family: 'Sora', sans-serif;
    overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
a{
    color: inherit;
    text-decoration:none;
}

#progressBarContainer {
    position: fixed;
    z-index: 10;
    background: rgba(255,255,255,0.05);
    width: 100%;
    top: 0;
    left: 0;
}
  
  
#progressBar {
    background: linear-gradient(to left, #8247e5, #8247e5);
    transform-origin: top left;
    transform: scale(0,0);
    opacity: 0;
}

#progressBarContainer,
#progressBar {
    height: 5px;
}
`

export default GlobalStyles;