import React from "react";
import styled from "styled-components";

const Section = styled.section`
min-height: 70vh;
width: 100%;
background-color: #FFFFFF;
display: block;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;
`

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontxl};

}
`;

const Traits = () => {
  return (
    <Section id="traits">

<br/>
        <Title>Attributes</Title><br/><br/>
		<h2  align="center">The Anatomy of Expressive Human Board Club NFT</h2>
		<div align="center"><br/><br/>
TRAIL TYPE - BASE - 25<br/><br/>
TRAIL TYPE - EAR - 4<br/><br/>
TRAIL TYPE - EYEBROWS - 9<br/><br/>
TRAIL TYPE - EYES - 9<br/><br/>
TRAIL TYPE - GLASSES - 13<br/><br/>
TRAIL TYPE - NOSES - 7<br/><br/>
TRAIL TYPE - SHIRTS - 15<br/><br/>
TRAIL TYPE - SMILES - 11<br/><br/></div>
		<br/>
		
		<h1 align="center">Specifications</h1>
<div align="center"><br/><br/>
TOTAL QTY: 10000<br/><br/>
TRAIT CLASSES: 8<br/><br/>
TOTAL TRAITS: 93<br/><br/>
SUPER RARE: 350+<br/><br/>
</div>
<br/>

	</Section>
  )
}
export default Traits
